/** @jsx jsx */
import { Disqus } from "gatsby-plugin-disqus"
import { MDXRenderer } from "gatsby-plugin-mdx"
import React from "react"
import { Heading, jsx } from "theme-ui"
import useBlogConfig from "../hooks/use-blog-config"
import BookPreview from "./book-preview"
import ItemTags from "./item-tags"
import Layout from "./layout"
import NewsletterForm from "./newsletter-form"
import SEO from "./seo"
import Share from "./share"

const PostComponent = ({ post, books }) => {
  const { url } = useBlogConfig()

  const disqusConfig = {
    url: url + post.slug,
    identifier: post.title,
    title: post.title,
  }

  return (
    <Layout>
      <SEO
        title={post.title}
        description={post.description ? post.description : post.excerpt}
        image={post.banner ? post.banner.childImageSharp.resize.src : undefined}
        pathname={post.slug}
      />
      <Heading variant="styles.h1">{post.title}</Heading>
      <p
        sx={{
          color: `secondary`,
          mt: 3,
          a: { color: `secondary` },
          fontSize: [1, 1, 2],
        }}
      >
        <time>{post.date}</time>
        {post.tags && (
          <React.Fragment>
            {` — `}
            <ItemTags tags={post.tags} />
          </React.Fragment>
        )}
        {post.timeToRead && ` — `}
        {post.timeToRead && <span>{post.timeToRead} min read</span>}
      </p>
      <Share post={post} />
      <section
        sx={{
          my: [3, 3, 4],
          ".gatsby-resp-image-figure": {
            my: [3, 3, 5],
          },
          ".gatsby-resp-image-wrapper": {
            my: 3,
          },
          ".gatsby-resp-image-figcaption": {
            textAlign: "center",
          },
        }}
      >
        <MDXRenderer>{post.body}</MDXRenderer>
      </section>
      <Share post={post} />
      <NewsletterForm />
      {books.map(b => (
        <BookPreview key={b.slug} book={b} />
      ))}
      <section
        sx={{
          my: [3, 3, 4],
        }}
      >
        <Disqus config={disqusConfig} />
      </section>
    </Layout>
  )
}

const Post = ({ data }) => {
  const { post, allBook } = data

  return <PostComponent post={post} books={allBook.nodes} />
}

export default Post
