/** @jsx jsx */
import addToMailchimp from "gatsby-plugin-mailchimp"
import { useState } from "react"
import {
  Box,
  Button,
  Card,
  Heading,
  Input,
  jsx,
  Label,
  Styled,
  Text,
} from "theme-ui"

var _ = require("lodash")

const NewsletterForm = props => {
  const [formState, setFormState] = useState({
    name: "",
    email: "",
    hasError: false,
    isSubmitted: false,
  })

  const handleChange = e => {
    setFormState({
      ...formState,
      hasError: false,
      [e.target.name]: e.target.value,
    })
  }

  const handleSubmit = async e => {
    e.preventDefault()

    if (_.isEmpty(formState.name) || _.isEmpty(formState.email)) {
      setFormState({
        hasError: true,
      })
      return
    }
    await addToMailchimp(formState.email, formState)
    setFormState({
      isSubmitted: true,
    })
  }

  if (formState.isSubmitted) {
    return (
      <Card py={4} my={4} sx={{ textAlign: "center" }} bg={"muted"}>
        <Heading>You are in! Check your email.</Heading>
      </Card>
    )
  }

  return (
    <Card sx={{ bg: "muted", textAlign: "center" }} px={4} pt={4} my={4}>
      <Heading variant="styles.h4">
        Want to be a Machine Learning expert?
      </Heading>
      <Text variant="styles.p" mb={3}>
        Join the weekly newsletter on Data Science, Deep Learning and Machine
        Learning in your inbox, curated by me! Chosen by{" "}
        <strong>10,000+</strong> Machine Learning practitioners. (There might be
        some exclusive content, too!)
      </Text>

      <Box as="form" onSubmit={handleSubmit} method="post">
        {formState.hasError && (
          <Styled.p sx={{ color: "error" }}>
            Error! You must enter all fields.
          </Styled.p>
        )}
        <Label htmlFor="name">Your Name*</Label>
        <Input name="name" mb={3} onChange={handleChange} />
        <Label htmlFor="email">Your Email*</Label>
        <Input type="email" name="email" mb={3} onChange={handleChange} />
        <Button>JOIN</Button>
      </Box>
      <Styled.p>You'll never get spam from me</Styled.p>
    </Card>
  )
}

export default NewsletterForm
