/** @jsx jsx */
import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookShareButton,
  InstapaperIcon,
  InstapaperShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  PocketIcon,
  PocketShareButton,
  RedditIcon,
  RedditShareButton,
  TwitterIcon,
  TwitterShareButton,
} from "react-share"
import { Flex, jsx, Styled } from "theme-ui"
import useBlogConfig from "../hooks/use-blog-config"

const Share = ({ post }) => {
  const { url, userTwitterHandle } = useBlogConfig()
  const postUrl = url + post.slug

  return (
    <section>
      <Styled.p sx={{ textTransform: "uppercase", fontWeight: "bold" }}>
        Share
      </Styled.p>

      <Flex
        sx={{
          px: [0, 4, 4],
          alignItems: `center`,
          justifyContent: `space-around`,
          flexFlow: `wrap`,
        }}
      >
        <EmailShareButton
          url={postUrl}
          subject={post.title}
          body={"Have a look at this: "}
        >
          <EmailIcon size={52} round />
        </EmailShareButton>
        <TwitterShareButton
          url={postUrl}
          title={post.title}
          via={userTwitterHandle}
          hashtags={post.tags.map(t => t.slug.replace("-", ""))}
        >
          <TwitterIcon size={52} round />
        </TwitterShareButton>
        <LinkedinShareButton
          url={postUrl}
          title={post.title}
          summary={post.description}
          source={url}
        >
          <LinkedinIcon size={52} round />
        </LinkedinShareButton>
        <RedditShareButton url={postUrl} title={post.title}>
          <RedditIcon size={52} round />
        </RedditShareButton>
        <InstapaperShareButton
          url={postUrl}
          title={post.title}
          description={post.description}
        >
          <InstapaperIcon size={52} round />
        </InstapaperShareButton>
        <PocketShareButton url={postUrl} title={post.title}>
          <PocketIcon size={52} round />
        </PocketShareButton>
        <FacebookShareButton
          url={postUrl}
          quote={post.title}
          hashtag={"#curiousily"}
        >
          <FacebookIcon size={52} round />
        </FacebookShareButton>
      </Flex>
    </section>
  )
}

export default Share
